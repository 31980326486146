import React, { useState } from 'react'
import { createRoot } from 'react-dom/client'
import { TextArea } from './TextArea'
import { Toggle } from './Toggle'
import { TextInput } from './TextInput'
import { Button } from './Button'
import { Select } from './Select'
import { AnimatedLogo } from './AnimatedLogo'
import { Modal } from './Modal'
import {
  BuildingLibraryIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  PlusIcon,
  RocketLaunchIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Checkbox } from './Checkbox'

const items = [
  {
    value: 'apple',
    label: 'Apple',
  },
  {
    value: 'asparagus',
    label: 'Asparagus',
  },
  {
    value: 'avacado',
    label: 'Avacado',
  },
  {
    value: 'aardvark',
    label: 'Aardvark',
  },
]

const Example = () => {
  const [message, setMessage] = useState('')
  const [value, setValue] = useState('')
  const [checked, setChecked] = useState(false)
  const [key, setKey] = useState(undefined)
  const [selectedFruit, setSelectedFruit] = useState<string | undefined>()
  const [search, setSearch] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [expandedOther, setExpandedOther] = useState(false)
  const [expandedOther2, setExpandedOther2] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const filteredItems = items.filter((it) => !search || it.label.includes(search) || it.value.includes(search))

  return (
    <main className="p-4 flex flex-col gap-4 dark:bg-gray-900">
      <a
        href="https://cron-input.8thday.dev"
        target="_blank"
        rel="noreferrer noopener"
        className="inline-block my-6 text-primary-400 focus:underline focus:text-primary-500 hover:underline hover:text-primary-500"
      >
        Cron Input Example
      </a>
      <div className="border border-transparent hover:border-orange-300 w-96">
        <TextArea
          placeholder="type, please"
          label="My textarea"
          description="oh yay descript"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          hideExtraLine
        />
      </div>
      <div className="border border-transparent hover:border-orange-300 min-w-96">
        <Toggle
          className="text-center"
          leftLabel="Off"
          leftDescription="shut it down"
          rightLabel="On"
          rightDescription="fire it up"
          checked={checked}
          setChecked={setChecked}
        />
      </div>
      <div className="border border-transparent hover:border-orange-300 min-w-96">
        <Toggle
          className="text-center"
          leftLabel="Off"
          leftDescription="shut it down"
          rightLabel="On"
          rightDescription="fire it up"
          neitherOff
          checked={checked}
          setChecked={setChecked}
        />
      </div>
      <div className="border border-transparent hover:border-orange-300 min-w-96">
        <Toggle
          className="text-center"
          rightLabel="On"
          rightDescription="fire it up"
          checked={checked}
          setChecked={setChecked}
        />
      </div>
      <div className="border border-transparent hover:border-orange-300 min-w-96">
        <Toggle
          className="text-center"
          leftLabel="On"
          leftDescription="fire it up"
          checked={checked}
          setChecked={setChecked}
        />
      </div>
      <div>
        <Checkbox
          checked={checked}
          setChecked={setChecked}
          label="Check me please"
          // description="I will be good to you."
        />
      </div>
      <div className="border border-transparent hover:border-orange-300 min-w-96 max-w-2xl">
        <TextInput
          key={key}
          label="words"
          description="fire it up"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <TextInput
          key={key}
          label="pre icon"
          description="so prety"
          value={value}
          PreIcon={ClockIcon}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <TextInput
          key={key}
          label="post icon"
          description="aewsome"
          PostIcon={TrashIcon}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <TextInput
          PreText="https://twitter.com/"
          key={key}
          label="pretext"
          PreIcon={BuildingLibraryIcon}
          description="type the rest"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
        <TextInput
          key={key}
          label="post text"
          PostText="o'clock"
          description="type the first part"
          value={value}
          errorMessage={!value && 'its all wrong!'}
          PostIcon={TrashIcon}
          onChange={(e) => setValue(e.target.value)}
          required
        />
      </div>
      <Button
        className="self-start"
        onClick={() => {
          setKey(new Date().toISOString())
        }}
      >
        Reset touched state
      </Button>
      <div className="flex items-end space-x-2">
        <Button
          PreIcon={DocumentDuplicateIcon}
          PostIcon={RocketLaunchIcon}
          onClick={() => setOpenModal(true)}
          variant="primary"
        >
          Primary (opens Modal)
        </Button>
        <Button variant="secondary" PostIcon={PlusIcon}>
          Secondary
        </Button>
        <Button variant="dismissive">Dismissive</Button>
        <Button variant="destructive" PreIcon={TrashIcon}>
          Destructive
        </Button>
        <Button variant="primary" PreIcon={PencilSquareIcon}></Button>
        <TextInput collapseDescriptionArea />
        <Select
          className="max-w-2xl w-full"
          label="Fruit"
          emptyItem={{ value: '', label: 'Clear Choice' }}
          items={filteredItems}
          value={selectedFruit}
          onValueChange={(v) => setSelectedFruit(v)}
          collapseDescriptionArea
        />
      </div>
      <h3>Animated Logo</h3>
      <div className="flex max-h-md overflow-hidden cursor-pointer text-primary-500  [--8thday-leaf-color:theme(colors.primary.700)] [--8thday-eight-color:theme(colors.primary.400)]">
        <AnimatedLogo className="w-24" onClick={() => setExpanded((e) => !e)} expanded={expanded} />
        <AnimatedLogo onClick={() => setExpandedOther((e) => !e)} expanded={expandedOther} />
        <AnimatedLogo id="wut" onClick={() => setExpandedOther2((e) => !e)} expanded={expandedOther2} />
      </div>
      {openModal && <Modal onClose={() => setOpenModal(false)}>Hey this is text</Modal>}
    </main>
  )
}

const root = createRoot(document.getElementById('app')!)

root.render(<Example />)
