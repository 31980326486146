import React, { ComponentProps, Dispatch, ReactNode, SetStateAction, useId } from 'react'

export interface CheckboxProps extends Omit<ComponentProps<'input'>, 'type' | 'onChange'> {
  label?: ReactNode
  description?: ReactNode
  checked?: boolean
  setChecked?: Dispatch<SetStateAction<boolean>>
  inputClass?: string
  containerClass?: string
  labelContainerClass?: string
  labelClass?: string
  descClass?: string
}

export const Checkbox = ({
  className = '',
  inputClass = '',
  containerClass = '',
  labelContainerClass = '',
  labelClass = '',
  descClass = '',
  id: propsId,
  checked,
  setChecked,
  label,
  description,
  ...props
}: CheckboxProps) => {
  const genId = useId()
  const id = propsId ?? genId

  return (
    <div className={`${className} relative flex items-start`}>
      <div className={`${containerClass} flex h-6 items-center cursor-pointer`}>
        <input
          className={`${inputClass} h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-primary-600 cursor-pointer`}
          id={id}
          aria-describedby={description && 'comments-description'}
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          {...props}
        />
      </div>
      {label && (
        <div className={`${labelContainerClass} ml-4`}>
          <label htmlFor={id} className={`${labelClass} font-medium text-gray-900 cursor-pointer`}>
            {label}
          </label>
          <p id="comments-description" className={`${descClass} text-sm text-gray-500`}>
            {description}
          </p>
        </div>
      )}
    </div>
  )
}
