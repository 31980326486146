import clsx from 'clsx'
import React, { ComponentProps } from 'react'
import { createPortal } from 'react-dom'

export interface ModalProps extends ComponentProps<'div'> {
  bgClass?: string
  overlayClasses?: string
  onClose(): void
  ignoreOutsideClick?: boolean
  portal?: boolean
  /**
   * Either a DOM node or a CSS selector e.g. #modal-root or .modal-container
   */
  portalElement?: Element | string
}

let initialBgClass = 'bg-gray-900/70'
let initialOverlayClasses = 'fixed inset-0 flex justify-center items-center z-[999]'
let modalBaseClasses = 'shadow-md bg-white rounded-lg p-2 sm:p-4 max-w-[95dvw] max-h-[95dvh] overflow-y-auto'

export const customizeModalClasses = ({
  bgClass: newBgClass,
  overlayClasses: newOverlayClasses,
  modalBaseClasses: newBase,
}: {
  /**
   * Change the color of the background atomically
   * @defaultvalue 'bg-gray-900/70'
   */
  bgClass?: string
  /**
   * Change the appearance or behavior of the background
   * @defaultvalue 'fixed inset-0 flex justify-center items-center z-[999]'
   */
  overlayClasses?: string
  /**
   * Change the appearance or behavior of the dialog box
   * @defaultvalue 'shadow-md bg-white rounded-lg p-2 sm:p-4 max-w-[95dvw] max-h-[95dvh] overflow-y-auto'
   */
  modalBaseClasses?: string
}) => {
  if (typeof newBgClass === 'string') {
    initialBgClass = newBgClass
  }

  if (typeof newOverlayClasses === 'string') {
    initialOverlayClasses = newOverlayClasses
  }

  if (typeof newBase === 'string') {
    modalBaseClasses = newBase
  }
}

export const Modal = ({
  className = '',
  bgClass = initialBgClass,
  overlayClasses = initialOverlayClasses,
  onClose,
  onClick,
  ignoreOutsideClick = false,
  children,
  portal = false,
  portalElement = document.body,
  ...props
}: ModalProps) => {
  const modal = (
    <div
      className={clsx(bgClass, overlayClasses)}
      onClick={(e) => {
        e.stopPropagation()
        if (!ignoreOutsideClick) {
          onClose()
        }
      }}
    >
      <div
        className={clsx(className, modalBaseClasses)}
        onClick={(e) => {
          e.stopPropagation()
          onClick?.(e)
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  )

  return portal
    ? createPortal(modal, typeof portalElement === 'string' ? document.querySelector(portalElement) : portalElement)
    : modal
}
